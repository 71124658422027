<template>
  <el-container :style="{ height: containerHeight }">
    <el-header class="login-navbar media_none">
      <div class="web">
        <div class="logo">
        </div>
        <div class="web-title">
          <div class="webName">银保镖</div>
        </div>
      </div>
      <!-- 用户信息 -->
      <div>
        <el-dropdown @command="handleHeadMenu">
          <span>
            {{ userName }}<i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>

    <el-container>
      <el-aside  width="200px">
        <el-menu :default-openeds="menuIndex" :unique-opened="true" :default-active="menuActive" active-text-color="#ffd04b">
          <el-submenu v-for="menus in menuList" :key="menus.guid" :index="menus.guid" popper-append-to-body>
            <template slot="title"><i :class="getMenuIcon(menus.menuIcon)"
                style="display: inline-block;width: 14px;margin: 0px 15px 3px 3px;"></i>{{ menus.menuName }}</template>
            <el-menu-item-group>
             
              <el-menu-item :key="menusChild.guid" :index="menusChild.guid"
                @click="jump(menusChild.routPath, menusChild.menuUrl, menusChild.guid)"
                v-for="menusChild in menus.childrens">{{ menusChild.menuName }}</el-menu-item>
           
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>

      <el-main>
        <template>
          <router-view />
        </template>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { getMenuTreeByPid } from '@/api/menu/index.js'
import { Message } from 'element-ui'
import store from '@/store/index.js'
import { TOKEN_KEY } from '@/utils/constant.js'
import config from '@/components/Config.vue'
export default {
  name: 'Home',
  data() {
    return {
      containerHeight: 0,
      menuList: [], // 用户角色菜单
      menuIndex: [], // 当前打开的 sub-menu 的 index 的数组
      menuActive: '', // 当前激活菜单的 index
      ulMenuShow: false
    }
  },
  computed: {
    userName() {
      return store.state.user.uname
    }

  },
  created() {
    
  },
  mounted() {
    this.changeFixed()
    this.initActiveMenu()
    this.getMenu()
  },
  watch: {},
  methods: {
     initActiveMenu() {
      const path = this.$route.query
      const menuGuid = path.menuGuid
      if (menuGuid) {
        this.menuActive = menuGuid
      }
    },
    clickDropdown(v) {
      // 选择语言
      if (v === 'home') {
        this.$router.push('/')
      } else if (v === 'translation') {
        this.$router.push('/system/ai/translation')
      } else if (v === 'txtImg') {
        this.$router.push('/system/ai/txtImg')
      } else if (v === 'txtRedact') {
        this.$router.push('/system/ai/txtRedact')
      } else if (v === 'ocr') {
        this.$router.push('/system/ai/ocrImg')
      } else if (v === 'imgRedact') {
        this.$router.push('/system/ai/imgRedact')
      }
    },
    menuBtn() {
      this.ulMenuShow = !this.ulMenuShow
    },
    handleBlur() {
      this.ulMenuShow = false
    },
    getMenuIcon(a) {
      if (a) {
        return a;
      } else {
        return "el-icon-menu";
      }
    },
    handleHeadMenu(command) {
      if (command === 'logout') {
        // debugger
        sessionStorage.removeItem(TOKEN_KEY);
        window.location.href = config.ssoUrl + '/logOut?appId='+config.appId+'&url=' + encodeURIComponent(config.siteUrl);
      }
    },
    jump(path, url, menuGuid) {
       console.log(path)
       console.log(url)
       console.log(menuGuid)
      // debugger
      if (url) {
        location.href = url;
      } else {
        this.$router.push(path + '?menuGuid=' + menuGuid);
      }

    },
    // 获取角色菜单
    getMenu() {
      let data = {
        parentId: '',
        menuAvailable: 1// 过滤隐藏的菜单
      };
      getMenuTreeByPid(data).then(res => {
        if (res.data.code === 200 && res.data.success) {
          this.menuList = res.data.result
        } else {
          Message.error(res.data.Message)
        }
      })
    },
    // 设置容器高度
    changeFixed() {
      this.containerHeight = `${document.documentElement.clientHeight}` + 'px' // 获取浏览器可视区域高度
      const that = this
      window.onresize = function () {
        this.containerHeight = `${document.documentElement.clientHeight}` + 'px'
        that.containerHeight = this.containerHeight
      }
    }
  }
}
</script>
<style>
.mobile_head {
  display: none;
}

@media only screen and (max-width: 768px) {
  [class*="media_none"] {
    display: none;
  }

  [class*="mobile_head"] {
    height: 50px;
    background: #001529;
    display: flex;
    color: #fff;
    line-height: 50px;
    font-size: 16px;
    padding: 0 10px;
    align-items: center;
  }
}

.mobile_head span {
  flex: 1;
}

.mobile_head i {
  float: right;
  font-size: 20px;
  color: #fff;
}

.mobile_head ul {
  position: absolute;
  right: 0px;
  top: 34px;
  padding: 20px;
  z-index: 11111111;
  background: #000;
  list-style: none;

}

.mobile_head ul li {
  height: 50px;
  line-height: 50px;
}

.mb8 {
  float: right;
  position: relative;
}

.web {
  width: 200px;
  background-color: #001529;
  color: white;
}

.login-navbar {
  padding: 0 0 0 0;
}

.el-dropdown {
  padding-right: 1rem;
}

.el-aside {
  background-color: #001529;
}

.el-submenu {
  background-color: #001529;
}

.el-menu {
  background-color: black;
}

.el-submenu__title {
  background-color: #001529;
}

.el-submenu.is-opened .el-submenu__title {
  background-color: #001529;
}

.el-menu-item.is-active {
  /* color: #1890ff; */
  /* background-color: #1890ff; */
}
</style>
